<style scoped>

.icu-ruleForm /deep/ .el-form-item {
  margin-bottom: 10px;
}

.icu-ruleForm /deep/ .el-select {
  width: 100%;
}

.icu-ruleForm /deep/ .el-input .el-input__inner, .icu-ruleForm /deep/ .el-textarea .el-textarea__inner {
  background-color: #F7F8FC;
  border-radius: 2px;
  border: 1px solid #F7F8FC;
}

.icu-ruleForm /deep/ .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.icu-ruleForm /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.icu-ruleForm /deep/ .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:after,
.icu-ruleForm /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  color: #ec1414;
  margin-left: 4px;
}

.icu-ruleForm /deep/ .el-form-item.is-error .el-input__inner,
.icu-ruleForm /deep/ .el-form-item.is-error .el-input__inner:focus,
.icu-ruleForm /deep/ .el-form-item.is-error .el-textarea__inner,
.icu-ruleForm /deep/ .el-form-item.is-error .el-textarea__inner:focus {
  border-color: #EC1414;
}

.icu-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 119px;
}

.icu-ruleForm /deep/ .el-form-item__label {
  float: none;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.icu-ruleForm /deep/ .icu-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icu-ruleForm /deep/ .checkbox_box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  /*flex-wrap: wrap;*/
  /*justify-content: space-between;*/
}

.icu-ruleForm /deep/ .icu-group .el-checkbox, .icu-ruleForm /deep/ .icu-group .el-radio {
  width: 33%;
  margin-right: 0;
  display: flex;
  align-items: center;
  padding: 5px 0;
}

/*.icu-ruleForm /deep/ .icu-group .el-checkbox, .icu-ruleForm /deep/ .icu-group .el-radio {*/
/*  width: 49%;*/
/*  margin-right: 0;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  padding: 5px 0;*/
/*}*/

.icu-ruleForm /deep/ .icu-group.icu-group-slot .el-radio {
  width: 30%;
}

.icu-ruleForm /deep/ .el-date-editor.el-input, .icu-ruleForm /deep/ .el-date-editor.el-input__inner {
  width: 100%;
}

.radio-input {
  width: 120px;
}

.double-wrap {
  display: flex;
  justify-content: space-between;
}

.double-wrap /deep/ .el-input {
  width: 47%;
}

.main-warp {
  display: flex;
  justify-content: space-between;
}

.main-warp /deep/ .el-button {
  /*width: 40%;*/
  padding-left: 15px;
}

.main-warp /deep/ .el-input {
  width: 70%;
  /*padding-left: 15px;*/
}

.group-title {
  color: #030303;
  font-size: 14px;
  padding-left: 10px;
  border-left: 3px solid #45A5E5;
  text-align: left;
  border-radius: 1px;
}

.group-tips {
  color: #8A96A3;
  font-size: 10px;
  text-align: left;
  padding-top: 5px;
}

.group-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.group-item {
  width: 45%;
}

.icu-ruleForm .group-flex .group-item {
  width: 45%;

}
</style>

<template>
  <div class="form-container">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="icu-ruleForm">
      <el-collapse v-model="activeName" accordion class="collapse-wrap">
        <el-collapse-item :title="formItem.title" :name="keys" v-for="(formItem,keys) in formData" :key="keys">
          <icuUpload :isSelect="isSelect" :equipment="equipment" :type="keys" @returnRes="returnRes" :baseUrl="baseUrl"
                     :imageList="formItem.image_list"
                     @returnImages="returnImages" @showDialog="showDialog"></icuUpload>
          <div v-for="(item,index) in formItem.list" :key="index">
            <template v-if="item.type === 'group'">
              <div class="group-title">{{ item.label }}</div>
              <div class="group-tips">{{ item.tips }}</div>
              <div class="group-tips">{{ item.remark }}</div>
              <div class="group-flex">
                <el-form-item
                    class="group-item"
                    v-for="(n) in item.arr"
                    :label="n.label"
                    :rules="n.rules"
                    :prop="`${keys}.${n.name}`">
                  <!--                  ruleForm[keys][item.list][i][n.name]-->
                  <el-input v-model="ruleForm[keys][n.name]" :placeholder="n.placeholder"></el-input>
                </el-form-item>
              </div>
            </template>
            <template v-else-if="item.type === 'radioPicker'">
              <el-form-item
                  :label="item.label"
                  :rules="item.rules"
                  :prop="`${keys}.${item.name}`">
                <el-radio-group v-model="ruleForm[keys][item.name]" class="icu-group">
                  <el-radio :label="childes.label" v-for="(childes,indexes) in item.options" :key="indexes">
                    {{ childes.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="group-flex">
                <div
                    v-for="(n,i) in item.arr"
                    :key="i"
                    class="group-item"
                >
                  <el-form-item
                      v-if="ruleForm[keys][item.name] === n.parentName"
                      :rules="n.rules"
                      :prop="`${keys}.${n.name}`"
                  >
                    <el-date-picker
                        v-model="ruleForm[keys][n.name]"
                        type="datetime"
                        value-format="yyyy-MM-dd"
                        :placeholder="n.placeholder"
                        :editable="false"
                        format="yyyy-MM-dd"
                        :default-value="new Date()">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>


            </template>
            <template v-else-if="item.parent">

              <template v-if="item.type === 'input'">
                <el-form-item
                    v-if="ruleForm[keys][item.parent_name] === '是' || ruleForm[keys][item.parent_name] === '有'"
                    :label="item.label"
                    :rules="item.rules"
                    :prop="`${keys}.${item.name}`"
                >
                  <el-input v-model="ruleForm[keys][item.name]"
                            :placeholder="item.placeholder ? item.placeholder :`请输入${item.label}`"></el-input>
                </el-form-item>
              </template>
              <template v-if="item.type === 'check'">
                <el-form-item
                    v-if="ruleForm[keys][item.parent_name] === '是'"
                    :label="item.label"
                    :rules="item.rules"
                    :prop="`${keys}.${item.name}`"
                >
                  <el-checkbox-group v-model="ruleForm[keys][item.name]" class="checkbox_box">
                    <el-checkbox :label="childes.label" name="type" v-for="(childes,indexes) in item.options"
                                 :key="indexes"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </template>
              <template v-if="item.type === 'date'">
                <el-form-item
                    v-if="ruleForm[keys][item.parent_name] === '是'"
                    :label="item.label"
                    :rules="item.rules"
                    :prop="`${keys}.${item.name}`"
                >
                  <el-date-picker
                      v-model="ruleForm[keys][item.name]"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :default-value="new Date()"
                      :placeholder="`请选择${item.label}`"
                      :editable="false">
                  </el-date-picker>
                </el-form-item>
              </template>

            </template>
            <template v-else>
              <el-form-item
                  :label="item.label"
                  :rules="item.rules"
                  :prop="`${keys}.${item.name}`"
              >
                <template v-if="item.type === 'input'">
                  <div v-if="item.double" class="double-wrap">
                    <el-input v-model="ruleForm[keys][item.name + '_STA']" :placeholder="item.sHolder"
                              :type="item.inputType" @change="changeValue"></el-input>
                    <el-input v-model="ruleForm[keys][item.name + '_END']" :placeholder="item.eHolder"
                              :type="item.inputType" @change="changeValue"></el-input>
                  </div>
                  <div v-else-if="item.main" class="main-warp">
                    <el-input v-model="ruleForm[keys][item.name]"
                              :placeholder="item.placeholder ? item.placeholder :`请输入${item.label}`"></el-input>
                    <el-button type="primary" @click="examine(ruleForm[keys][item.name])">检查</el-button>
                  </div>

                  <el-input v-else v-model="ruleForm[keys][item.name]"
                            :placeholder="item.placeholder ? item.placeholder :`请输入${item.label}`"></el-input>

                </template>


                <template v-if="item.type === 'select'">
                  <el-select v-model="ruleForm[keys][item.name]" :placeholder="`请选择${item.label}`">
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in item.options"
                               :key="index"></el-option>
                  </el-select>
                </template>
                <template v-if="item.type === 'check'">

                  <el-checkbox-group v-model="ruleForm[keys][item.name]" class="checkbox_box" @change="checkChange($event,keys,item.name)">
                    <el-checkbox :label="childes.label" name="type" v-for="(childes,indexes) in item.options"
                                 :key="indexes"></el-checkbox>
                  </el-checkbox-group>

                </template>

                <template v-if="item.type === 'radio'">
                  <el-radio-group v-model="ruleForm[keys][item.name]"
                                  :class="['icu-group',item.hasInput ? 'icu-group-slot' : ''] " >
                    <el-radio :label="childes.label" v-for="(childes,indexes) in item.options" :key="indexes">
                      {{ childes.label }}
                    </el-radio>

                    <template v-if="item.hasInput && ruleForm[keys][item.name] === 1">
                      <el-input class="radio-input" :placeholder="`请输入${item.slotLabel}`"
                                v-model="ruleForm[keys][item.slotName]"></el-input>
                    </template>
                  </el-radio-group>
                </template>
                <template v-if="item.type === 'textarea'">
                  <el-input type="textarea" v-model="ruleForm[keys][item.name]"
                            :placeholder="`请输入${item.label}`"></el-input>
                </template>
                <template v-if="item.type === 'date'">
                  <el-date-picker
                      v-model="ruleForm[keys][item.name]"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :default-value="new Date()"
                      :placeholder="`请选择${item.label}`"
                      :editable="false">
                  </el-date-picker>
                </template>
                <template v-if="item.type === 'dateTime'">
                  <el-date-picker
                      v-model="ruleForm[keys][item.name]"
                      type="datetime"
                      value-format="yyyy-MM-dd HH"
                      :placeholder="`请选择${item.label}`"
                      :editable="false"
                      format="yyyy-MM-dd HH"
                      :default-value="new Date()">
                  </el-date-picker>
                </template>
              </el-form-item>

            </template>

          </div>

        </el-collapse-item>
      </el-collapse>

    </el-form>
    <icuDialog :visible="dialogVisible" @handleClose="handleClose" :imgType="imgType" :imgUrl="imgUrl"
               @saveHandle="saveHandle" @returnImages="returnImages">
    </icuDialog>

  </div>
</template>

<script>
import icuUpload from '@/components/icu-upload'
import icuDialog from "@/components/icu-dialog";

export default {
  name: "icu-form",
  components: {
    icuUpload,
    icuDialog,

  },
  props: {
    isSelect: {},
    dataObj: {
      type: Object,
    },
    formData: {
      type: Object,
    },
    type: {
      type: String
    },
    auditStatus: {
      type: String
    },
    ruleFormData: {
      type: Object,
    },
    equipment: {
      type: Boolean,
    },
    examineStatus: {
      type: Boolean,
    }
  },
  data() {
    return {
      nowDate: new Date(),
      HoursTime: '12',
      activeName: 'resident_admit_note',
      ruleForm: {
        resident_admit_note: {title: '入院记录', list: []},
        prescribed_medication: {title: '医嘱用药', list: []},
        progress_note: {title: '病程记录', list: []},
      },
      rules: {},
      dialogVisible: false,
      imgUrl: '',
      imgType: '',
      baseUrl: "",
      checkedCities: ['上海', '北京'],
    };
  },
  created() {
    const date = new Date();
    let h = date.getHours().toString()
    this.HoursTime = h

  },
  methods: {

    examine(value) {
      console.log(value)
      if (value === '') {
        this.$message('请输入住院号');
        return;
      }
      console.log(this.ruleForm)
      this.$emit('examine', value)
    },
    //分段输入时，字段拼接
    changeValue() {
      Object.values(this.ruleForm).forEach(item => {
        Object.keys(item).forEach(n => {
          if (n.indexOf('STA') !== -1 || n.indexOf('END') !== -1) {
            let s = n.substring(0, n.length - 4)
            item[s] = (item[s + '_STA'] ? item[s + '_STA'] : '') + '-' + (item[s + '_END'] ? item[s + '_END'] : '')
          }
        })
      })
    },
    progressNoteVer(type) {
      // let arr = Object.keys(object);
      // console.log('progress_note==>>', this.ruleForm['progress_note'])
      let pitchList = this.formData['progress_note'].list
      let note = this.ruleForm['progress_note'];
      let note_obj = {};
      let note_message = [];
      Object.keys(note).forEach((n) => {
        if (n.indexOf('_3') !== -1 || n.indexOf('_4') !== -1 || n.indexOf('_5') !== -1 || n.indexOf('_6') !== -1) {
          // note_arr.push(n.substring(0, n.length - 2))
          let key = n.substring(0, n.length - 2);
          if (note_obj[key]) {
            note_obj[key].push(n)
          } else {
            note_obj[key] = [n]
          }
        }
      })
      if (note_obj && type !== 'STAGED') {
        for (const x in note_obj) {
          let result = note_obj[x].map(n => note[n] === '')
          let isTips = result.find(n => n === false)
          if (isTips === undefined) {
            pitchList.forEach(m => {
              if (m.name === x) {
                let tips_txt = `【病程记录+化验结果】中${m.label}使用后24h、48h、72h、96h至少填写一个`
                note_message.push(tips_txt)
              }
            })
          }
        }
      }
      let data = {};
      Object.keys(note).forEach((n) => {
        if (n.indexOf('_1') !== -1 || n.indexOf('_2') !== -1 || n.indexOf('_3') !== -1 || n.indexOf('_4') !== -1 || n.indexOf('_5') !== -1 || n.indexOf('_6') !== -1) {
          // note_arr.push(n.substring(0, n.length - 2))
          let key = n.substring(0, n.length - 2);
          if (data[key]) {
            data[key].push(note[n] ? note[n] : '无')
          } else {
            data[key] = [note[n] ? note[n] : '无']
          }
        }
      })
      Object.keys(data).forEach(n => {
        this.ruleForm['progress_note'][n] = data[n].join('-');
      })



      if (note_message.length > 0 && type !== 'STAGED') {
        this.activeName = 'progress_note'
        this.$message.error(note_message[0])
        return false
      } else {
        return true;
      }
      // console.log(this.ruleForm['progress_note'][n])

    },
    // 表单提交
    submitForm() {

      if(this.auditStatus === 'STAGED' || this.$parent.auditStatus === 'STAGED'){
        this.progressNoteVer('STAGED');
        let ruleForm = {};
        ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
        this.$emit('returnData', ruleForm)
        return false;

      }
      if(!this.examineStatus){
        this.$message.warning('请检查住院ID编号是否重复')
        return false;
      }
      let classify = '';
      let message = []
      Object.keys(this.ruleForm).forEach(item => {
        if (this.ruleForm[item].image_list.length <= 0) {
          if (item === 'resident_admit_note') {
            classify = '【入院记录】中未上传图片'
          } else if (item === 'prescribed_medication') {
            classify = '【医嘱用药】中未上传图片'
          } else if (item === 'progress_note') {
            classify = '【病程记录+化验结果】中未上传图片'
          }
          message.push(classify)
        }
      })
      if (message.length > 0) {
        this.$message.error(message[0])
        return;
      }

      // this.ruleForm[key].image_list
      this.$refs.ruleForm.validate((valid, object) => {
        if (valid) {
          let result = this.progressNoteVer();

          let ruleForm = {};
          ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          // delete  ruleForm.list;
          if (result) {
            console.log('通过了验证', ruleForm)
            // return;
            this.$emit('returnData', ruleForm)
          }

        } else {
          this.returnMessage(object)
          // console.log('error submit!!');
          return false;
        }
      });
    },
    //提交认证返回提示信息
    returnMessage(object) {
      let arr = Object.keys(object);
      let messageArr = [];
      let classifyArr = [];
      arr.forEach(item => {
        // console.log(item)
        let itemArr = item.split('.')
        let classify = '';
        classifyArr.push(itemArr[0])
        if (itemArr[0] === 'resident_admit_note') {
          classify = '【入院记录】中'
        } else if (itemArr[0] === 'prescribed_medication') {
          classify = '【医嘱用药】中'
        } else if (itemArr[0] === 'progress_note') {
          classify = '【病程记录+化验结果】中'
        }
        let pitchList = this.formData[itemArr[0]].list
        pitchList.forEach(n => {
          if (n.name === itemArr[1]) {
            classify += `【${n.label}】不能为空`
            messageArr.push(classify)
          }else{
            messageArr.push(classify += '有必填项')
          }
        })
      })
      classifyArr = Array.from(new Set(classifyArr))
      this.activeName = classifyArr[0]
      this.$message.error(messageArr[0])
    },
    //表单重置
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },

    //识别返回值
    returnRes(data) {
      console.log(data)
      let key = Object.keys(data)[0];
      let obj = Object.assign({},this.ruleForm[key],data[key])
      console.log(obj)
      this.ruleForm[key] = obj;
    },
    //返回图片路径
    returnImages(data) {
      let key = Object.keys(data)[0];
      console.log(key)
      console.log(data[key])
      this.ruleForm[key].image_list.push(data[key]);
      console.log(this.ruleForm[key].image_list)
    },

    //单选框类型选中时触发
    radioChange(value, name, keys) {
      //给单选框赋值
      this.formData[keys][name] = value;
      // 判断点击是否是否手术字段，来判断手术时间是否必填
      if (name === 'is_operation') {
        this.formData[keys].list.forEach(item => {
          if (item.name === 'operation_time') {
            item.hide = !value;
            item.rules[0].required = !!value;
          }
        })
      }
      this.$forceUpdate()
    },

    // 打码图片
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog(data) {
      this.dialogVisible = true;
      this.imgUrl = data.url
      this.imgType = data.type
    },
    saveHandle(base64) {
      this.baseUrl = base64
    },

    checkChange(value,keys,name){
      console.log(value)
      console.log(keys)
      console.log(name)
      console.log(this.ruleForm[keys][name])
      this.ruleForm[keys][name] = value;
      this.$forceUpdate()
    }

  },
  watch: {
    formData: {
      handler(value) {
        console.log(value);
        this.ruleForm.resident_admit_note = {...value.resident_admit_note}
        this.ruleForm.progress_note = {...value.progress_note}
        this.ruleForm.prescribed_medication = {...value.prescribed_medication}
        // //判断是否手术字段展示手术时间
        // if (!this.ruleForm.resident_admit_note.is_operation) {
        //   this.ruleForm.resident_admit_note.list.forEach(item => {
        //     if (item.name === 'operation_time' && item.rules) {
        //       item.hide = true;
        //       item.rules[0].required = false;
        //     }
        //   })
        // }
      },
      deep: true,
      immediate: true
    },

  }
}
</script>
