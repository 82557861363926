// eslint-disable-next-line no-unused-vars
const medical = [
  {
    type: 'input',
    name: 'patient_number',
    label: '住院ID编号',
    rules: [
      {required: true, message: '请输入住院号', trigger: 'blur'},
    ],
    main:true,
  },
  {
    type: 'radio',
    name: 'sex',
    label: '患者性别',
    rules: [
      {required: true, message: '请选择患者性别', trigger: 'change'},
    ],
    options: [{
      label: '男',
      value: 1,
    }, {
      label: '女',
      value: 2
    },]
  },
  {
    type: 'input',
    name: 'age',
    label: '患者年龄',
    rules: [
      {required: true, message: '请输入患者年龄', trigger: 'blur'},
    ],
  },
  {
    type: 'input',
    name: 'height',
    label: '患者体重（kg）',
    rules: [
      {required: true, message: '请输入患者体重', trigger: 'blur'},
    ],
  },

  {
    type: 'date',
    name: 'admission_time',
    label: '入院时间',
    rules: [
      {required: true, message: '请选择入院时间', trigger: 'change'}
    ],
  },

  {
    type: 'radio',
    name: 'is_hypertension',
    label: '是否有高血压病史',
    rules: [
      {required: true, message: '请选择是否有高血压病史', trigger: 'change'}
    ],
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },]
  },
  {
    type: 'radio',
    name: 'is_perioperative_period',
    label: '是否围术期',
    rules: [
      {required: true, message: '请选择是否围术期', trigger: 'change'}
    ],
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },]
  },

  {
    type: 'check',
    name: 'arrhythmia_type',
    label: '入院诊断结果中心律失常类型 ',
    rules: [
      {type: 'array', required: true, message: '请至少选择一个入院诊断', trigger: 'change'}
    ],
    options: [
      {
        label: '窦速',
      }, {
        label: '房颤/房扑',
      }, {
        label: '室上性心动过速',
      }, {
        label: '房速',
      }, {
        label: '室速',
      }, {
        label: '室速/室颤风暴',
      },]
  },
  {
    type: 'check',
    name: 'types_of_cd',
    label: '其它诊断结果或合并疾病类型',
    rules: [
      {type: 'array', required: true, message: '请至少选择一个入院诊断', trigger: 'change'}
    ],
    options: [
      {
        label: '慢性心衰',
      }, {
        label: '急性心衰',
      }, {
        label: '射血分数保留心衰',
      }, {
        label: '射血分数降低心衰',
      }, {
        label: '急性冠脉综合征ACS',
      }, {
        label: '急性心梗',
      },
      {
        label: '其他',
      },
    ]
  },

  // {
  //   type: 'input',
  //   name: 'other_admission',
  //   label: '其他诊断结果',
  // },

  {
    type: 'input',
    name: 'hr',
    label: '心率HR(次/分)(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    rules: [
      { required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'bp',
    label: '血压BP(mmHg)(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    rules: [
      { required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'temperature',
    label: '体温T(℃)(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    rules: [
      { required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ],},
  {
    type: 'input',
    name: 'pulse',
    label: '脉搏P（次/分）(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    rules: [
      { required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ],},
  {
    type: 'input',
    name: 'breathe',
    label: '呼吸R（次/分）(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    rules: [
      { required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ], },
  {
    type: 'radio',
    name: 'is_Melt',
    label: '是否进行消融',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },],
    rules: [
      { required: true, message: '请选择是否进行消融', trigger: 'change'}
    ]
  },

]
const prescribed = [
  {
    type: 'dateTime',
    name: 'start_time',
    label: '艾司洛尔使用起始时间',
    hour:true,
    rules: [
      {required: true, message: '请选择使用时间', trigger: 'change'},
    ]
  },
  {
    type: 'dateTime',
    name: 'end_time',
    label: '艾司洛尔使用结束时间',
    hour:true,

    rules: [
      {required: true, message: '请选择结束时间', trigger: 'change'},
    ]
  },
  {
    type: 'radio',
    name: 'asle_use_method',
    label: '艾司洛尔使用方法',
    options: [{
      label: '持续不间断使用',
      value: 0,
    }, {
      label: '持续间断使用',
      value: 1,
    },],
    rules: [
      {required: true, message: '请选择使用方法', trigger: 'change'},
    ]
  },

  {
    type: 'input',
    name: 'dose_range',
    label: '艾司洛尔使用剂量范围(mg/h)',
    placeholder: '请输入使用剂量范围（mg/h）',
    sHolder:'最小剂量（mg/h）',
    eHolder:'最大剂量（mg/h）',
    double:true,
    rules: [
      {required: true, message: '请输入使用剂量范围（mg/h）', trigger: 'blur'},
    ]
  },
  {
    type: 'input',
    name: 'total',
    label: '艾司洛尔使用总量(mg)',
    placeholder: '请输入使用总量（mg）',
    rules: [
      {required: true, message: '请输入使用总量（mg）', trigger: 'blur'},
    ],

  },

  {
    type: 'check',
    name: 'combination_medication',
    label: '合并用药情况 ',
    rules: [
      {type: 'array', required: true, message: '请选择合并用药情况', trigger: 'change'}
    ],
    options: [
      {
        label: '乌拉地尔',
      }, {
        label: '硝普钠',
      }, {
        label: '地尔硫卓',
      }, {
        label: '右美托咪定',
      }, {
        label: '胺碘酮',
      }, {
        label: '维拉帕米',
      }, {
        label: '西地兰',
      },
      {
        label: '米力农 ',
      },
      {
        label: '左西孟旦 ',
      },
      {
        label: '无以上合并用药 ',
      }
    ]

  },


]
//病程记录
const course = [



  {
    type: 'input',
    name: 'hr',
    label: '心率HR(次/分)(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    sHolder:'开始用药',
    eHolder:'结束用药',
    double:true,
    inputType:'number',
    rules: [
      {required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ], },
  {
    type: 'input',
    name: 'bp',
    label: '血压BP(mmHg)(注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    sHolder:'开始用药',
    eHolder:'结束用药',
    double:true,

    rules: [
      {required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ], },
  {
    type: 'input',
    name: 'temperature',
    label: '体温T(℃)   (注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    sHolder:'开始用药',
    eHolder:'结束用药',
    double:true,
    rules: [
      {required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ], },
  // {
  //   type: 'input',
  //   name: 'pulse',
  //   label: '血肌酐（umol/L）',
  //   placeholder: '请输入初始检查结果',
  // },
  // {
  //   type: 'input',
  //   name: 'breathe',
  //   label: '呼吸R（次/分）',
  //   placeholder: '请输入初始检查结果',
  // },
  {
    type: 'input',
    name: 'xjg',
    label: '血肌酐(umol/L)  (注：没有填写＇无＇)',
    sHolder:'开始用药',
    eHolder:'结束用药',
    placeholder: '请输入初始检查结果',
    double:true,
    rules: [
      {required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ], },
  {
    type: 'input',
    name: 'Bnp',
    label: 'BNP/NT-ProBNP   (注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    sHolder:'开始用药',
    eHolder:'结束用药',
    double:true,
    rules: [
      {required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'jgdb',
    label: '肌钙蛋白I   (注：没有填写＇无＇)',
    placeholder: '请输入初始检查结果',
    sHolder:'开始用药',
    eHolder:'结束用药',
    double:true,
    rules: [
      {required: true, message: '请输入初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'radio',
    name: 'patient_transition',
    label: '患者转归',
    rules: [
      {required: true, message: '请选择患者转归情况', trigger: 'change'}
    ],
    options: [{
      label: '好转',
      value: 0,
    }, {
      label: '未愈',
      value: 1,
    }, {
      label: '死亡',
      value: 2,

    },]
  },
  {
    type: 'radio',
    name: 'is_adverse_reaction',
    label: '不良反应',
    rules: [
      {required: true, message: '请选择不良反应', trigger: 'change'}
    ],
    options: [{
      label: '无',
      value: 0
    }, {
      label: '有',
      value: 1
    },]
  },
  {
    type: 'input',
    name: 'adverse_reaction_detail',
    label: '',
    placeholder: '请描述具体情况及措施'
  },
]

export {
  medical,
  prescribed,
  course
}
